/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.57
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The SortObject model module.
 * @module model/SortObject
 * @version 1.0.1
 */
export default class SortObject {
  /**
   * Constructs a new <code>SortObject</code>.
   * @alias module:model/SortObject
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SortObject</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SortObject} obj Optional instance to populate.
   * @return {module:model/SortObject} The populated <code>SortObject</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SortObject();
      if (data.hasOwnProperty('empty'))
        obj.empty = ApiClient.convertToType(data['empty'], 'Boolean');
      if (data.hasOwnProperty('sorted'))
        obj.sorted = ApiClient.convertToType(data['sorted'], 'Boolean');
      if (data.hasOwnProperty('unsorted'))
        obj.unsorted = ApiClient.convertToType(data['unsorted'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {Boolean} empty
 */
SortObject.prototype.empty = undefined;

/**
 * @member {Boolean} sorted
 */
SortObject.prototype.sorted = undefined;

/**
 * @member {Boolean} unsorted
 */
SortObject.prototype.unsorted = undefined;

