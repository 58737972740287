<template>
  <div class="container">
    <router-view />
  </div>
</template>
<style>

#app {
  font-family: 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50 !important;
  direction: rtl;

}

.container {
  display: flex;
  padding-right: unset !important;
  padding-left: unset !important;
  margin-right: unset !important;
  margin-left: unset !important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50 !important;
}

nav a.router-link-exact-active {
  color: #42b983 !important;
}
 .container {
   max-width: 100% !important;
} 

.add-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    justify-self: right;
}

.add-button:hover {
    background-color: #4bc44f;
}

.modal-xl {
    max-width: 99% !important;
    max-height: 99% !important;
}


select {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
}

/* Customizing the dropdown menu */
select:focus {
  border-color: #007bff;
  outline: none;
}

select:hover {
  background-color: #f5f5f5;
}

option:hover {
  background-color: #eee;
}
.edit-button,
.delete-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 5px;
}

.edit-button:hover,
.delete-button:hover {
    background-color: #0056b3 !important;
}

.delete-button {
    background-color: #dc3545 !important;
}

.delete-button:hover {
    background-color: #b3001a !important;
}

.fa-pen {
    margin-right: 5px;
}

.error {
  color: red;
}
.btn-outline-primary {
    color: rgb(241, 88, 39) !important;
    border-color: rgb(241, 88, 39) !important;
    border-top-color: rgb(241, 88, 39) !important;
    border-right-color: rgb(241, 88, 39) !important;
    border-bottom-color: rgb(241, 88, 39) !important;
    border-left-color: rgb(241, 88, 39) !important;
}

.btn-outline-primary:hover {
    background-color: rgb(241, 88, 39) !important;
    border-color: rgb(241, 88, 39) !important;
    color: rgb(33, 37, 41) !important;
}
/* .table {
    width: 100%;
    border-collapse: collapse;
} */

.table th,
.table td {
    padding: 8px;
    /* border: 1px solid #ccc; */
}

.table th {
    /* background-color: #f2f2f2; */
    border: 1px;
    border-collapse: collapse;
}

.btn-secondary{
  background-color: #590305 !important;
}
.btn-secondary:focus{
  background-color: #590305 !important;
}

.btn-secondary:hover{
  background-color: #590305 !important;
}
.fas{
  color: #590305;
}
button .fas{
  color: unset;
}
.modal-static {
    pointer-events: none; /* Disables interactions */
}
.modal-static .modal-content {
    pointer-events: auto; /* Enables interactions inside the modal content */
}
</style>
