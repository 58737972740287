import { getDatabase, ref, set } from "firebase/database";
import { getStorage, ref as sRef, uploadBytes,getDownloadURL  } from "firebase/storage";


export async function saveToFirebase(path, data) {
    const database = getDatabase();
    await set(ref(database, path), data);
}


export async function uploadFileToFirebase(file, folder, fileName) {
    try {
        const storage = getStorage();
        const storageRef = sRef(storage,`${folder}/${fileName}`);
        // await storageRef.put(file);
        // const downloadURL = await storageRef.getDownloadURL();
        var snapshot = await uploadBytes(storageRef, file);
        if(snapshot){
            return await getDownloadURL(snapshot.ref);
        }
        return null;
    } catch (error) {
        console.error('Error uploading file to Firebase Storage:', error);
        throw error;
    }
}