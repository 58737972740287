<template>
    <div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" ref="modal">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
                    </div>
                    <div class="modal-body">
                        <div style="display: flex; flex-direction: column; width: 100%;height: 50%;">
                            <UploadImage @save="saveImage" :url="image" />
                            <label for="arabicName">الاسم العربي:</label>
                            <input id="arabicName" type="input" style="margin-right: 10px;" v-model="categoryNameAr" />
                            <label for="englishName">الاسم الانجليزي:</label>
                            <input id="englishName" type="input" style="margin-right: 10px;" v-model="categoryNameEn" />
                            <label for="category">القسم الأب:</label>
                            <select id="category" v-model="selectedCategory">
                                <option v-for="option in options" :value="option" :key="option">
                                    {{ option.nameAr }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" 
                        @click="closeModal">إلغاء</button>
                        <button type="button" class="btn btn-primary" @click="save">حفظ</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { onMounted, nextTick, ref, defineProps, watch } from "vue";
import { ApiClient, CategoryControllerApi, CategoryEntity } from "../api/index.js"
import UploadImage from "./UploadImage.vue";
import { uploadFileToFirebase,saveToFirebase} from "../firebase/Utils.js";

// const props = defineProps(['categories']);
const props = defineProps(["category"]);
const emit = defineEmits(["save"]);

const apiClient = new ApiClient();

const categoryApi = new CategoryControllerApi(apiClient);

const options = ref([]);

const title = ref("قسم جديد");
const catId = ref(null);
const categoryNameAr = ref('');
const categoryNameEn = ref('');
const selectedCategory = ref(null);
const image = ref(null);


watch(
    () => props.category,
    (newCategory) => {
        if (newCategory) {
            title.value = "تعديل قسم";
            catId.value=newCategory.id;
            categoryNameAr.value = newCategory.nameAr;
            categoryNameEn.value = newCategory.nameEn;
            selectedCategory.value = newCategory.parent;
            image.value=newCategory.imgCover;

        } else {
            title.value="قسم جديد";
            catId.value=null;
            categoryNameAr.value = "";
            categoryNameEn.value = "";
            selectedCategory.value = null;
            image.value=null;
        }
    }
);


onMounted(() => {
    nextTick(() => {
        // Initialize Bootstrap modals
        const modalEls = document.querySelectorAll('.modal');
        modalEls.forEach((el) => {
            new window.bootstrap.Modal(el);
        });
        // console.log(props.categories);
        // options.push(...props.categories);

        // //get categories
        categoryApi.getCategories({ isChild: false }, (error, data, response) => {
            if (error) {
                console.error(error);
                return;
            }
            options.value = data.content;
        });
    });
});

function languageChanged(lang) {
    selectedLanguage.value = lang.value;
}

async function saveImage(imageData) {
    const randomName = Math.random().toString(36).substring(2, 15);
    image.value = await uploadFileToFirebase(imageData, "categories/covers", `${randomName}.jpg`);
}


function save() {
    try {
        var category = new CategoryEntity();

        // const categoryIdValue = (selectedCategory.value||'').trim();
        // const langValue = (selectedLanguage.value || '').trim();
        const categoryAr = categoryNameAr.value;// (categoryNameAr.value).trim();
        const categoryEn = categoryNameEn.value;// (categoryNameAr.value).trim();

        category.nameAr = categoryAr || (() => { throw new Error('الاسم العربي لا يمكن أن يكون فارغ') })();
        category.nameEn = categoryEn || (() => { throw new Error('الاسم الانجليزي لا يمكن أن يكون فارغ') })();
        category.parent = { id: selectedCategory.value.id };
        category.id=catId.value;
        category.isMediaCategory = false;
        category.imgCover=image.value;


        categoryApi.addCategory(category, localStorage.getItem("token"), (error, data, response) => {
            if (error == null) {
                emit("save", category);
                location.reload();
            }
            else {
                alert(response);
                alert(error);
                console.log(error);
                alert(data);
            }
        });
    } catch (error) {
        alert(error.message);
    }

}

function closeModal() {
    document.location.reload();
    }
</script>
