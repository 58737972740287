/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.57
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ArticlesMediaEntity from './ArticlesMediaEntity';
import AuthorEntity from './AuthorEntity';
import CategoryEntity from './CategoryEntity';

/**
 * The ArticleEntity model module.
 * @module model/ArticleEntity
 * @version 1.0.1
 */
export default class ArticleEntity {
  /**
   * Constructs a new <code>ArticleEntity</code>.
   * @alias module:model/ArticleEntity
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ArticleEntity</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ArticleEntity} obj Optional instance to populate.
   * @return {module:model/ArticleEntity} The populated <code>ArticleEntity</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ArticleEntity();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('mediaEntities'))
        obj.mediaEntities = ApiClient.convertToType(data['mediaEntities'], [ArticlesMediaEntity]);
      if (data.hasOwnProperty('content'))
        obj.content = ApiClient.convertToType(data['content'], 'String');
      if (data.hasOwnProperty('category'))
        obj.category = CategoryEntity.constructFromObject(data['category']);
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('coverImg'))
        obj.coverImg = ApiClient.convertToType(data['coverImg'], 'String');
      if (data.hasOwnProperty('lang'))
        obj.lang = ApiClient.convertToType(data['lang'], 'Number');
      if (data.hasOwnProperty('addedAt'))
        obj.addedAt = ApiClient.convertToType(data['addedAt'], 'Date');
      if (data.hasOwnProperty('lastEdit'))
        obj.lastEdit = ApiClient.convertToType(data['lastEdit'], 'Date');
      if (data.hasOwnProperty('author'))
        obj.author = AuthorEntity.constructFromObject(data['author']);
      if (data.hasOwnProperty('lastEditBy'))
        obj.lastEditBy = AuthorEntity.constructFromObject(data['lastEditBy']);
      if (data.hasOwnProperty('deletedAt'))
        obj.deletedAt = ApiClient.convertToType(data['deletedAt'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ArticleEntity.prototype.id = undefined;

/**
 * @member {Array.<module:model/ArticlesMediaEntity>} mediaEntities
 */
ArticleEntity.prototype.mediaEntities = undefined;

/**
 * @member {String} content
 */
ArticleEntity.prototype.content = undefined;

/**
 * @member {module:model/CategoryEntity} category
 */
ArticleEntity.prototype.category = undefined;

/**
 * @member {String} title
 */
ArticleEntity.prototype.title = undefined;

/**
 * @member {String} coverImg
 */
ArticleEntity.prototype.coverImg = undefined;

/**
 * @member {Number} lang
 */
ArticleEntity.prototype.lang = undefined;

/**
 * @member {Date} addedAt
 */
ArticleEntity.prototype.addedAt = undefined;

/**
 * @member {Date} lastEdit
 */
ArticleEntity.prototype.lastEdit = undefined;

/**
 * @member {module:model/AuthorEntity} author
 */
ArticleEntity.prototype.author = undefined;

/**
 * @member {module:model/AuthorEntity} lastEditBy
 */
ArticleEntity.prototype.lastEditBy = undefined;

/**
 * @member {Date} deletedAt
 */
ArticleEntity.prototype.deletedAt = undefined;

