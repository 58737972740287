<template>
    <div class="container-fluid" v-if="showTable">

        <h2  class="text-center">المستخدمون</h2>
        <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center">
                <button class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" @click="openModal()">
                    <i class="fas fa-plus"></i> جديد
                </button>
                <SearchComponent @submit:search="startSearch"/>
            </div>            
        </div>
        <UsersModal :userToEdit="selectedUser" />

        <!-- </a> -->
        <div class="table-responsive">
        <table class="table">
            <thead>

                <tr>
                    <th>المستخدم</th>
                    <th>الصلاحيات</th>
                    <th>الحالة</th>
                    <th>عمليات</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id">
                    <!-- <td>{{ users.id }}</td> -->
                    <td>{{ user.username }}</td>
                    <td>{{ user.privileges == 'ROLE_USER' ? "محدود" : "مشرف" }}</td>
                    <td>{{ user.enabled ? "نشط" : 'موقف' }}</td>
                    <td>
                        <button class="edit-button" @click="editUser(user)" data-toggle="modal" data-target="#exampleModal">
                            تعديل
                        </button>
                        <button class="delete-button" @click="disableUser(user.id, !user.enabled)">
                            {{ user.enabled ? "إيقاف" : "تفعيل" }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-start">
                    <PaginationComponent :total-elements="totalElements" :page-size="pageSize" :current-page="currentPage"
                        @page-changed="handlePageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { AuthenticationControllerApi,SearchControllerApi, ApiClient } from "../api/index.js";
import { ref } from 'vue'
import SearchComponent from "../components/SearchComponent.vue";
import UsersModal from "../components/UsersModal.vue";
import PaginationComponent from '../components/PaginationComponent.vue';


const apiClient = new ApiClient();
const usersApi = new AuthenticationControllerApi(apiClient);
const searchApi = new SearchControllerApi(apiClient);
const users = ref([]);


const searchMode=ref(false);
const query=ref(null);
const totalElements = ref(0);
const pageSize = ref(10);
const currentPage = ref(0);
const selectedUser = ref(null);


let showTable = true;

const fetchUsers = () => {
    if(searchMode.value==false){
    usersApi.getUsers(localStorage.getItem("token"), { page: currentPage.value, size: pageSize.value }, (error, data, response) => {
        if (error)
            alert(error);
      parseResult(data);
    });
}else{
    searchApi.searchUsersByUserName(currentPage.value,pageSize.value,query.value,localStorage.getItem('token'),(error,data,response)=>{
        if (error)
            alert(error);
      parseResult(data);
    });
}
};

function parseResult(data){
    users.value = data.content;
    currentPage.value = data.pageable.pageNumber;
    pageSize.value = data.pageable.pageSize;
    totalElements.value = data.totalElements;
}

const editUser = (user) => {
    selectedUser.value = user;

}

const disableUser = (id, newState) => {
    usersApi.changeEnableState(newState, id, localStorage.getItem("token"), (error, data, response) => {
        if (data.body.second)
            location.reload();
        else {
            alert("حصل خطأ غير معروف");
        }
    });
}
const openModal = () => {
    selectedUser.value = null;
};
const handlePageChange = (newPage) => {
    currentPage.value = newPage;
    fetchUsers();
};

const startSearch = (_) => {
    currentPage.value=0;
    searchMode.value=true;
    query.value=_;
    fetchUsers();
};

fetchUsers();
</script>
