/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.57
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The ArticlesMediaDTO model module.
 * @module model/ArticlesMediaDTO
 * @version 1.0.1
 */
export default class ArticlesMediaDTO {
  /**
   * Constructs a new <code>ArticlesMediaDTO</code>.
   * @alias module:model/ArticlesMediaDTO
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ArticlesMediaDTO</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ArticlesMediaDTO} obj Optional instance to populate.
   * @return {module:model/ArticlesMediaDTO} The populated <code>ArticlesMediaDTO</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ArticlesMediaDTO();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('tag'))
        obj.tag = ApiClient.convertToType(data['tag'], 'String');
      if (data.hasOwnProperty('articleId'))
        obj.articleId = ApiClient.convertToType(data['articleId'], 'String');
      if (data.hasOwnProperty('mediaType'))
        obj.mediaType = ApiClient.convertToType(data['mediaType'], 'Number');
      if (data.hasOwnProperty('mediaUrl'))
        obj.mediaUrl = ApiClient.convertToType(data['mediaUrl'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
ArticlesMediaDTO.prototype.id = undefined;

/**
 * @member {String} tag
 */
ArticlesMediaDTO.prototype.tag = undefined;

/**
 * @member {String} articleId
 */
ArticlesMediaDTO.prototype.articleId = undefined;

/**
 * @member {Number} mediaType
 */
ArticlesMediaDTO.prototype.mediaType = undefined;

/**
 * @member {String} mediaUrl
 */
ArticlesMediaDTO.prototype.mediaUrl = undefined;

