/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.57
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import AlbumEntity from './AlbumEntity';

/**
 * The MediaEntity model module.
 * @module model/MediaEntity
 * @version 1.0.1
 */
export default class MediaEntity {
  /**
   * Constructs a new <code>MediaEntity</code>.
   * @alias module:model/MediaEntity
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>MediaEntity</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MediaEntity} obj Optional instance to populate.
   * @return {module:model/MediaEntity} The populated <code>MediaEntity</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MediaEntity();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('mediaUrl'))
        obj.mediaUrl = ApiClient.convertToType(data['mediaUrl'], 'String');
      if (data.hasOwnProperty('album'))
        obj.album = AlbumEntity.constructFromObject(data['album']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
MediaEntity.prototype.id = undefined;

/**
 * @member {String} title
 */
MediaEntity.prototype.title = undefined;

/**
 * @member {String} description
 */
MediaEntity.prototype.description = undefined;

/**
 * @member {String} mediaUrl
 */
MediaEntity.prototype.mediaUrl = undefined;

/**
 * @member {module:model/AlbumEntity} album
 */
MediaEntity.prototype.album = undefined;

