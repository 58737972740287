/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.61
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import SortObject from './SortObject';

/**
 * The PageableObject model module.
 * @module model/PageableObject
 * @version 1.0.1
 */
export default class PageableObject {
  /**
   * Constructs a new <code>PageableObject</code>.
   * @alias module:model/PageableObject
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PageableObject</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PageableObject} obj Optional instance to populate.
   * @return {module:model/PageableObject} The populated <code>PageableObject</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PageableObject();
      if (data.hasOwnProperty('offset'))
        obj.offset = ApiClient.convertToType(data['offset'], 'Number');
      if (data.hasOwnProperty('sort'))
        obj.sort = SortObject.constructFromObject(data['sort']);
      if (data.hasOwnProperty('pageNumber'))
        obj.pageNumber = ApiClient.convertToType(data['pageNumber'], 'Number');
      if (data.hasOwnProperty('pageSize'))
        obj.pageSize = ApiClient.convertToType(data['pageSize'], 'Number');
      if (data.hasOwnProperty('paged'))
        obj.paged = ApiClient.convertToType(data['paged'], 'Boolean');
      if (data.hasOwnProperty('unpaged'))
        obj.unpaged = ApiClient.convertToType(data['unpaged'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {Number} offset
 */
PageableObject.prototype.offset = undefined;

/**
 * @member {module:model/SortObject} sort
 */
PageableObject.prototype.sort = undefined;

/**
 * @member {Number} pageNumber
 */
PageableObject.prototype.pageNumber = undefined;

/**
 * @member {Number} pageSize
 */
PageableObject.prototype.pageSize = undefined;

/**
 * @member {Boolean} paged
 */
PageableObject.prototype.paged = undefined;

/**
 * @member {Boolean} unpaged
 */
PageableObject.prototype.unpaged = undefined;

