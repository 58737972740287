/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.57
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import AlbumEntity from './AlbumEntity';
import ArticleEntity from './ArticleEntity';

/**
 * The CategoryEntity model module.
 * @module model/CategoryEntity
 * @version 1.0.1
 */
export default class CategoryEntity {
  /**
   * Constructs a new <code>CategoryEntity</code>.
   * @alias module:model/CategoryEntity
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CategoryEntity</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CategoryEntity} obj Optional instance to populate.
   * @return {module:model/CategoryEntity} The populated <code>CategoryEntity</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CategoryEntity();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('nameAr'))
        obj.nameAr = ApiClient.convertToType(data['nameAr'], 'String');
      if (data.hasOwnProperty('nameEn'))
        obj.nameEn = ApiClient.convertToType(data['nameEn'], 'String');
      if (data.hasOwnProperty('parent'))
        obj.parent = CategoryEntity.constructFromObject(data['parent']);
      if (data.hasOwnProperty('isMediaCategory'))
        obj.isMediaCategory = ApiClient.convertToType(data['isMediaCategory'], 'Boolean');
      if (data.hasOwnProperty('imgCover'))
        obj.imgCover = ApiClient.convertToType(data['imgCover'], 'String');
      if (data.hasOwnProperty('articleEntities'))
        obj.articleEntities = ApiClient.convertToType(data['articleEntities'], [ArticleEntity]);
      if (data.hasOwnProperty('albumEntities'))
        obj.albumEntities = ApiClient.convertToType(data['albumEntities'], [AlbumEntity]);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
CategoryEntity.prototype.id = undefined;

/**
 * @member {String} nameAr
 */
CategoryEntity.prototype.nameAr = undefined;

/**
 * @member {String} nameEn
 */
CategoryEntity.prototype.nameEn = undefined;

/**
 * @member {module:model/CategoryEntity} parent
 */
CategoryEntity.prototype.parent = undefined;

/**
 * @member {Boolean} isMediaCategory
 */
CategoryEntity.prototype.isMediaCategory = undefined;

/**
 * @member {String} imgCover
 */
CategoryEntity.prototype.imgCover = undefined;

/**
 * @member {Array.<module:model/ArticleEntity>} articleEntities
 */
CategoryEntity.prototype.articleEntities = undefined;

/**
 * @member {Array.<module:model/AlbumEntity>} albumEntities
 */
CategoryEntity.prototype.albumEntities = undefined;

