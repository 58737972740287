<template>
    <div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" ref="modal">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
                    </div>
                    <div class="modal-body">
                        <div style="display: flex; flex-direction: column; width: 100%;height: 50%;">
                            <UploadImage @save="saveImage" :url="image" />
                            <label for="title">العنوان:</label>
                            <input id="title" type="input" style="margin-right: 10px;" v-model="articleTitle" />
                            <label for="category" v-if="isHPUser">القسم:</label>
                            <select id="category" v-model="selectedCategory" v-if="isHPUser">
                                <option v-for="option in options" :value="option" :key="option">
                                    {{ option.nameAr }}
                                </option>
                            </select>
                            <br />
                            <LanguageRadioButtons @selectedOption="languageChanged" :initialValue="langVal.value" />
                            <br />
                            <WYSIWYG :content="editorContent" v-model="editorContent" />
                        </div>
                        <!-- <AddEditArticle /> -->
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" 
                        @click="closeModal">إلغاء</button>
                        <button type="button" class="btn btn-primary" @click="save">حفظ</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
// import AddEditArticle from "./AddEditArticle.vue";
import WYSIWYG from '@/components/WYSIWYG.vue';
import { onMounted, nextTick, ref, defineProps, watch } from "vue";
import { ApiClient, ArticleControllerApi, ArticleEntity, CategoryControllerApi, PagesControllerApi } from "../api/index.js"
import LanguageRadioButtons from './LanguageRadioButtons.vue';
import UploadImage from "./UploadImage.vue";
import { uploadFileToFirebase,saveToFirebase} from "../firebase/Utils.js";


const props = defineProps(["articleToEdit"]);
const emit = defineEmits(["save"]);

const apiClient = new ApiClient();

const articlesApi = new ArticleControllerApi(apiClient);
const categoryApi = new CategoryControllerApi(apiClient);
const pagesApi = new PagesControllerApi(apiClient);

const options = ref([]);

const title = ref("مقال جديد");
const selectedCategory = ref('');
const selectedLanguage = ref("1");
const editorContent = ref('');
// const editorContentId = ref([]);
const articleTitle = ref('');
const articleId = ref(null);
const langVal = ref('1');
const image = ref(null);
const imageUploaded=ref(false);

const isHPUser = JSON.parse(localStorage.getItem("isHPUser") || "false");
console.log(isHPUser);

watch(
    () => props.articleToEdit,
    (newArticle) => {
        console.log(newArticle);
        if (newArticle) {
            title.value = "تعديل مقال";
            articleId.value = newArticle.id;
            selectedCategory.value = newArticle.category;
            selectedLanguage.value = newArticle.lang;
            articleTitle.value = newArticle.title;
            langVal.value = newArticle.lang;
            image.value=newArticle.imgUrl;
            editorContent.value="";
            editorContent.value=newArticle.content;
            imageUploaded.value=true;
            // newArticle.contentEntities.forEach(contentEntity => {
            //     editorContent.value += contentEntity.content;
            //     editorContentId.value.push(contentEntity.id);
            // });

        } else {
            title.value = "مقال جديد";
            articleId.value = null;
            selectedLanguage.value = "1";
            selectedCategory.value = null;
            langVal.value = "1";
            image.value=null;
            editorContent.value = "";
        }
    }
);

async function saveImage(imageData) {
    imageUploaded.value=false;
    const randomName = Math.random().toString(36).substring(2, 15);
    image.value = await uploadFileToFirebase(imageData, "articles/covers", `${randomName}.jpg`);
    imageUploaded.value=true;
}

onMounted(() => {
    nextTick(() => {
        // Initialize Bootstrap modals
        const modalEls = document.querySelectorAll('.modal');
        modalEls.forEach((el) => {
            new window.bootstrap.Modal(el);
        });

        //get categories
        categoryApi.getCategories({ page: 0, size: 100, isChild: true }, (error, data, response) => {
            if (error) {
                console.error(error);
                return;
            }
            options.value = data.content;
        });
    });
});

function languageChanged(lang) {
    selectedLanguage.value = lang.value;
    // You can do additional processing with the selected option here
}

function save() {
    try {
        if(imageUploaded.value==false){
            alert("لم يتم رفع صورة بعد!");
            return;
        }
        var article = new ArticleEntity();

        // const categoryIdValue = (selectedCategory.value||'').trim();
        const langValue = selectedLanguage.value;//(selectedLanguage.value || '').trim();
        const titleValue = articleTitle.value;// (articleTitle.value).trim();
        const contentValue = (editorContent.value || '').trim();
        article.title = titleValue || (() => { throw new Error('العنوان لا يمكن أن يكون فارغ') })();
        if(isHPUser==true|| articleId.value){
            article.category = selectedCategory.value || (() => { throw new Error('يرجئ إختيار القسم') })();
        }
        article.lang = langValue || (() => { throw new Error('يرجئ إختيار اللغة') })();
        article.content = contentValue || (() => { throw new Error('يبدو أن محتوى المقالة فارغ') })();
        article.addedAt = "";
        if (!articleId.value) {
            article.author = { id: localStorage.getItem("author") };
            article.lastEditBy = {id:parseInt( localStorage.getItem("author"))};
            article.id = "";
        }
        else {
            article.id = articleId.value;
            // content.id = editorContentId.value[0];
            article.lastEditBy = { id: localStorage.getItem("author") };
            article.author = { id: parseInt( localStorage.getItem("author")) };

        }
        article.deletedAt = "";
        article.lastEdit = "";
        article.published = "";
        // article.contentEntities = [content];
        article.coverImg=image.value;
        console.log(image.value);
        // article.=null;
        if (!articleId.value) {
            if(isHPUser==true)
            articlesApi.addArticle(article, localStorage.getItem("token"), (error, data, response) => {
                if (error == null)
                    location.reload();
                else {
                    alert(response);
                    alert(error);
                    console.log(error);
                    alert(data);
                }

            });
            else
            pagesApi.addPArticle(article, localStorage.getItem("token"), (error, data, response) => {
                if (error == null)
                    location.reload();
                else {
                    alert(response);
                    alert(error);
                    console.log(error);
                    alert(data);
                }

            });

        } else {
            if(isHPUser==true)
            articlesApi.updateArticle(article, localStorage.getItem("token"), (error, data, response) => {
                if (error == null)
                    location.reload();
                else {
                    alert(response);
                    alert(error);
                    console.log(error);
                    alert(data);
                }

            });
            else
            pagesApi.updatePArticle(article, localStorage.getItem("token"), (error, data, response) => {
                if (error == null)
                    location.reload();
                else {
                    alert(response);
                    alert(error);
                    console.log(error);
                    alert(data);
                }

            });
        }
    } catch (error) {
        alert(error.message);
    }

}

function closeModal() {
    document.location.reload();
    }
</script>