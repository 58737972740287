<template>
    <div>
        <div class="modal fade" id="albumModal" tabindex="-1" role="dialog" aria-labelledby="albumModalLabel"
            aria-hidden="true" ref="modal" :class="{ 'modal-static': isSaving }">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="albumModalLabel">{{ title }}</h5>
                    </div>
                    <div class="modal-body">
                        <div style="display: flex; flex-direction: column; width: 100%; height: 50%;">
                            <UploadImage @save="saveImage" :url="image" />
                            <label for="title">العنوان:</label>
                            <input id="title" type="input" style="margin-right: 10px;" v-model="albumTitle" />
                            <label for="category">القسم:</label>
                            <select id="category" v-model="selectedCategory">
                                <option v-for="option in options" :value="option" :key="option">
                                    {{ option.nameAr }}
                                </option>
                            </select>
                            <br />
                            <textarea class="form-control" v-model="albumDesc" placeholder="وصف..."></textarea>
                            <br />
                            <LanguageRadioButtons @selectedOption="languageChanged" :initialValue="langVal" />
                            <br />
                            
                            <button class="btn" @click="addMedia">
                                {{ mediaType==0?'إضافة صورة':'إضافة صوت' }}
                            </button>
                            <div class="gallery1">                                
                            <div v-for="(media, index) in mediaContents" :key="media.id">
                                <MediaContent :media="media" @deleteMedia="deleteMedia(index)" :mediaType="mediaType" :tempData="false" @updateMedia="updateMetaData(index,true,$event)"/>
                            </div>

                            <div v-for="(url, index) in tempUrls">
                                <MediaContent :media="url" @deleteMedia="deleteFile(index)" :mediaType="mediaType" :tempData="true" @updateMedia="updateMetaData(index,false,$event)"/>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" 
                        @click="closeModal">إلغاء</button>

                        <button type="button" class="btn btn-primary" @click="save" :disabled="isSaving">حفظ</button>
                        <p v-if="uploadProgress.total > 0">
                            رفع {{ uploadProgress.completed }}/{{ uploadProgress.total }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { onMounted, nextTick, ref, defineProps, watch } from "vue";
import { ApiClient, MediaControllerApi, AlbumEntity, CategoryControllerApi, MediaEntity } from "../api/index.js"
import LanguageRadioButtons from './LanguageRadioButtons.vue';
import MediaContent from './MediaContent.vue';
import UploadImage from './UploadImage.vue';
import { uploadFileToFirebase } from "../firebase/Utils.js";

const props = defineProps(["albumToEdit","mediaType"]);
const emit = defineEmits(["save"]);

const apiClient = new ApiClient();

const albumApi = new MediaControllerApi(apiClient);
const categoryApi = new CategoryControllerApi(apiClient);

const options = ref([]);

const title = ref("ألبوم جديد");
const selectedCategory = ref('');
const selectedLanguage = ref("1");
const albumTitle = ref('');
const albumId = ref(null);
const langVal = ref('1');
const image = ref(null);
const mediaContents = ref([]);
const mediaType = ref(0);
const albumDesc=ref('');

const uploadProgress = ref({});
const tempFiles = ref([]); // Temporary storage for file objects
const tempUrls = ref([]); // Temporary storage for urls objects

const isSaving = ref(false);

const acceptableImageTypes = ['image/jpeg', 'image/png'];
const acceptableAudioTypes = ['audio/mpeg', 'audio/mp3'];

let map = {};

function updateMetaData(index,isMedia, newData) {
    console.log(newData);
  map[isMedia?mediaContents.value.at(index):tempFiles.value.at(index)] = newData;
}

watch(
    () => props.mediaType,
    (newMediaType) => {
        mediaType.value=newMediaType;
    }
);

watch(
    () => props.albumToEdit,
    (newAlbum) => {
        uploadProgress.total=0;
        if (newAlbum) {
            title.value = "تعديل ألبوم";
            albumId.value = newAlbum.id;
            selectedCategory.value = newAlbum.category;
            selectedLanguage.value = newAlbum.lang;
            albumTitle.value = newAlbum.title;
            langVal.value = newAlbum.lang;
            image.value = newAlbum.coverImg;
            mediaContents.value = newAlbum.contentEntities;
            albumDesc.value=newAlbum.description;
        } else {
            resetForm();
        }
    }
);

function resetForm() {
    title.value = "ألبوم جديد";
    albumId.value = null;
    selectedLanguage.value = "1";
    selectedCategory.value = null;
    langVal.value = "1";
    image.value = null;
    mediaContents.value = [];
    albumDesc.value='';
}

async function saveImage(imageData) {
    const randomName = Math.random().toString(36).substring(2, 15);
    image.value = await uploadFileToFirebase(imageData, "albums/covers", `${randomName}.jpg`);
}

function addMedia() {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.multiple = true;
  fileInput.accept = mediaType.value === 0 ? acceptableImageTypes.join(',') : acceptableAudioTypes.join(',');
  fileInput.onchange = async (e) => {
    const files = Array.from(e.target.files);
    files.forEach(file => {
      if ((mediaType.value === 0 && acceptableImageTypes.includes(file.type)) ||
          (mediaType.value === 1 && acceptableAudioTypes.includes(file.type))) {
        tempFiles.value.push({ file });
         tempUrls.value.push({ url: URL.createObjectURL(file)});
      }
    });
  };
  fileInput.click();
}

function deleteMedia(index) {
    mediaContents.value = [...mediaContents.value.slice(0, index), ...mediaContents.value.slice(index + 1)];
}

function deleteFile(index) {
    tempFiles.value = [...tempFiles.value.slice(0, index), ...tempFiles.value.slice(index + 1)];
    tempUrls.value = [...tempUrls.value.slice(0, index), ...tempUrls.value.slice(index + 1)];
}

function languageChanged(lang) {
    selectedLanguage.value = lang.value;
}

async function save() {
    isSaving.value = true;
    uploadProgress.value.total = tempFiles.value.length;
    uploadProgress.value.completed = 0;

    try {      
        const album = new AlbumEntity();
        album.title = albumTitle.value || (() => { throw new Error('العنوان لا يمكن أن يكون فارغ') })();
        album.category = selectedCategory.value || (() => { throw new Error('يرجئ إختيار القسم') })();
        album.lang = selectedLanguage.value || (() => { throw new Error('يرجئ إختيار اللغة') })();
        album.description = albumDesc.value || (() => { throw new Error('وصف الألبوم تاقص') })();
        album.coverImg = image.value;
        for (const { file } of tempFiles.value) {
            let media=new MediaEntity();
            const randomName = Math.random().toString(36).substring(2, 15);
            const uploadUrl = await uploadFileToFirebase(file, `albums/${mediaType.value === 0 ? 'images' : 'audio'}`, `${randomName}`);
            media.mediaUrl = uploadUrl; // Save the URL after upload
            // media.title=map[file].title;
            // media.description=map[file].description;
            if(map[file]){
                map[media]=map[file];
                delete map[file];
            }
            mediaContents.value.push(media);
            uploadProgress.value.completed++;
        }
        for(var i=0;i<mediaContents.value.length;i++){
            if(map[mediaContents.value.at(i)]){
                var data = map[mediaContents.value[i]];
                mediaContents.value[i].title=data.title;
                mediaContents.value[i].description=data.description;
            }
        }
       

        album.contentEntities = mediaContents.value;
        album.addedAt = new Date().toISOString();
        album.author={id:localStorage.getItem("author")}

        if (!albumId.value) {
            albumApi.addMedia(album,localStorage.getItem("token"), (error, data, response) => {
                if (!error) location.reload();
                else console.error(error);
            });
        } else {
            album.id = albumId.value;
            albumApi.updateMedia(album,localStorage.getItem("token"), (error, data, response) => {
                if (!error) location.reload();
                else console.error(error);
            });
        }
    } catch (error) {
        alert(error.message);
    } finally {
        isSaving.value = false;
    }
}

onMounted(() => {
    nextTick(() => {
        const modalEls = document.querySelectorAll('.modal');
        modalEls.forEach((el) => {
            new window.bootstrap.Modal(el);

        });

        categoryApi.getMediaCategories({ page: 0, size: 100, isChild: true }, (error, data, response) => {
            if (!error) {
                options.value = data.content;
            } else {
                console.error(error);
            }
        });
    });
});

function closeModal() {
    document.location.reload();
    }
</script>

<style scoped>
.modal-static {
    pointer-events: none; /* Disables interactions */
}
.modal-static .modal-content {
    pointer-events: auto; /* Enables interactions inside the modal content */
}
.gallery1 {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto; /* Add horizontal scrollbar if content overflows */
}

.gallery1 > div {
    display: inline-block;
    vertical-align: top; /* Aligns items vertically at the top */
}
*::-webkit-scrollbar {
    width: 2px;
    background-color: rgb(255, 255, 255);
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(255, 253, 253);
  }
 *::-webkit-scrollbar-thumb {
    background-color: rgb(241, 88, 39);
    outline: 1px solid rgb(241, 88, 39);
}
</style>

