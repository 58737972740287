/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.57
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The LoginDTO model module.
 * @module model/LoginDTO
 * @version 1.0.1
 */
export default class LoginDTO {
  /**
   * Constructs a new <code>LoginDTO</code>.
   * @alias module:model/LoginDTO
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>LoginDTO</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LoginDTO} obj Optional instance to populate.
   * @return {module:model/LoginDTO} The populated <code>LoginDTO</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LoginDTO();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('displayName'))
        obj.displayName = ApiClient.convertToType(data['displayName'], 'String');
      if (data.hasOwnProperty('displayNameEn'))
        obj.displayNameEn = ApiClient.convertToType(data['displayNameEn'], 'String');
      if (data.hasOwnProperty('imgUrl'))
        obj.imgUrl = ApiClient.convertToType(data['imgUrl'], 'String');
      if (data.hasOwnProperty('auth'))
        obj.auth = ApiClient.convertToType(data['auth'], 'Boolean');
      if (data.hasOwnProperty('tdata'))
        obj.tdata = ApiClient.convertToType(data['tdata'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
LoginDTO.prototype.id = undefined;

/**
 * @member {String} displayName
 */
LoginDTO.prototype.displayName = undefined;

/**
 * @member {String} displayNameEn
 */
LoginDTO.prototype.displayNameEn = undefined;

/**
 * @member {String} imgUrl
 */
LoginDTO.prototype.imgUrl = undefined;

/**
 * @member {Boolean} auth
 */
LoginDTO.prototype.auth = undefined;

/**
 * @member {String} tdata
 */
LoginDTO.prototype.tdata = undefined;

