<template>
    <div class="container-fluid">
        <h2 class="text-center">قائمة المقالات</h2>
        <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center">
                <button class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" @click="openModal()">
                    <i class="fas fa-plus"></i> جديد
                </button>
                <SearchComponent @submit:search="startSearch"/>
            </div>
        </div>
        <ArticleModal :articleToEdit="selectedArticle"/>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>العنوان</th>                        
                        <th v-if="isHPUser==true">القسم</th>
                        <th v-if="isHPUser==true">الكاتب</th>
                        <th>اللغة</th>
                        <th>تاريخ الإضافة</th>
                        <th>آخر تعديل</th>
                        <th>آخر تعديل بواسطة</th>
                        <th>عمليات</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="article in articles" :key="article.id">
                        <td>{{ article.title }}</td>
                        <td v-if="isHPUser==true">{{ article.category.nameAr }}</td>
                        <td>{{ article.author.displayName }}</td>
                        <td>{{ article.lang == 1 ? "عربي" : "أنجليزي" }}</td>
                        <td>{{ formatDateTime(article.addedAt) }}</td>
                        <td>{{ article.lastEdit?formatDateTime(article.lastEdit):"لم يسبق تعديله" }}</td>
                        <td>{{ article.lastEditBy?article.lastEditBy.displayName:"" }}</td>
                        <td>
                            <a class="btn btn-info" :href="'https://hazemdev.web.app/article/' + article.id" target="_blank">
                            عرض
                        </a>
                            <button class="edit-button" @click="editArticle(article)" data-toggle="modal"
                                data-target="#exampleModal">
                                تعديل
                            </button>
                            <button class="delete-button" @click="deleteArticle(article.id)">
                                حذف
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-end">
                    <PaginationComponent :total-elements="totalElements" :page-size="pageSize" :current-page="currentPage"
                        @page-changed="handlePageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { ArticleControllerApi, ApiClient,PagesControllerApi,SearchControllerApi } from "../api/index.js";
import { ref } from 'vue'
import ArticleModal from "@/components/ArticleModal.vue";
import PaginationComponent from '@/components/PaginationComponent.vue';
import SearchComponent from '@/components/SearchComponent.vue';


const apiClient = new ApiClient();
const articleApi = new ArticleControllerApi(apiClient);
const pagesApi = new PagesControllerApi(apiClient);
const searchApi = new SearchControllerApi(apiClient);

const articles = ref([]);

const searchMode=ref(false);
const query=ref(null);
const totalElements = ref(0);
const pageSize = ref(10);
const currentPage = ref(0);
const selectedArticle = ref({});
const isHPUser = JSON.parse(localStorage.getItem("isHPUser") || "false");

const fetchArticles = () => {
    if(searchMode.value==false){
    if(isHPUser==true)
    articleApi.getAllArticles(localStorage.getItem("token"), { page: currentPage.value, size: pageSize.value }, (error, data, response) => {
        parseResult(data);
    });
    else{
        pagesApi.getAllPArticles(localStorage.getItem("token"), { page: currentPage.value, size: pageSize.value }, (error, data, response) => {
            parseResult(data);
        });
    }
}else{
    if(isHPUser==true)
    searchApi.searchArticles(currentPage.value,pageSize.value,query.value,1,(error,data,response)=>{
        parseResult(data);
    });
    else
    searchApi.searchPArticles(currentPage.value,pageSize.value,query.value,1,localStorage.getItem('author'),(error,data,response)=>{
        parseResult(data);
    });
}

};
function parseResult(data){
        articles.value = data.content;
        currentPage.value = data.pageable.pageNumber;
        pageSize.value = data.pageable.pageSize;
        totalElements.value = data.totalElements;
}

const editArticle = (article) => {
    selectedArticle.value = article;
}

const deleteArticle = (id) => {
    if(isHPUser==true)
    articleApi.deleteArticle(id, localStorage.getItem("token"), (error, data, response) => {
        // Handle the response
        if (data == true)
            location.reload();
    });
    else 
    pagesApi.deletePArticle(id, localStorage.getItem("token"), (error, data, response) => {
        // Handle the response
        if (data == true)
            location.reload();
    });
}

const formatDateTime = (dateObj) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Intl.DateTimeFormat('ar-US', options).format(dateObj);
}

const openModal = () => {
  selectedArticle.value = null;
};

const startSearch = (_) => {
    currentPage.value=0;
    searchMode.value=true;
    query.value=_;
    fetchArticles();
};

const handlePageChange = (newPage) => {
    currentPage.value = newPage;
    fetchArticles();
};

fetchArticles();
</script>
