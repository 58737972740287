<template>
  <div>
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" id="toggleSwitch" v-model="isChecked" @change="toggleSwitch" />
      <label class="form-check-label" style="padding-right: 18px;" for="toggleSwitch">{{ _label }}</label>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';


const props = defineProps({
  label: {
    type: String,
    required: true
  },
  initialValue: {
    type: Boolean,
    default: false
  }
});

const isChecked = ref(props.initialValue);
const _label = ref(props.label);
const emit = defineEmits(['toggle']);

const toggleSwitch = () => {
  emit('toggle', isChecked.value)
}
watch(
  () => props.label,
  (newLabel) => {
    console.log(newLabel);
    _label.value = newLabel;
  }
);
watch(
  () => props.initialValue,
  (_initialValue) => {
    isChecked.value = _initialValue;
  }
);
</script>

<style scoped>
.form-check-input:checked {
  background-color: #4CAF50;
  border-color: #4CAF50;
}
</style>
