/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.57
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import AlbumDTO from '../model/AlbumDTO';
import AlbumEntity from '../model/AlbumEntity';
import PageAlbumDTO from '../model/PageAlbumDTO';

/**
* MediaController service.
* @module api/MediaControllerApi
* @version 1.0.1
*/
export default class MediaControllerApi {

    /**
    * Constructs a new MediaControllerApi. 
    * @alias module:api/MediaControllerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addMedia operation.
     * @callback moduleapi/MediaControllerApi~addMediaCallback
     * @param {String} error Error message, if any.
     * @param {Object{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/AlbumEntity} body 
     * @param {String} authorization 
     * @param {module:api/MediaControllerApi~addMediaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addMedia(body, authorization, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addMedia");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling addMedia");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/v1/media/manage/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteMedia operation.
     * @callback moduleapi/MediaControllerApi~deleteMediaCallback
     * @param {String} error Error message, if any.
     * @param {Object{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} id 
     * @param {module:api/MediaControllerApi~deleteMediaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    deleteMedia(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteMedia");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/v1/media/manage/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getAllAlbums operation.
     * @callback moduleapi/MediaControllerApi~getAllAlbumsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PageAlbumDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} authorization 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page  (default to <.>)
     * @param {Number} opts.size  (default to <.>)
     * @param {module:api/MediaControllerApi~getAllAlbumsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getAllAlbums(authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling getAllAlbums");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'page': opts['page'],'size': opts['size']
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PageAlbumDTO;

      return this.apiClient.callApi(
        '/v1/media/manage/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getMediaByCategory operation.
     * @callback moduleapi/MediaControllerApi~getMediaByCategoryCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PageAlbumDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} cat 
     * @param {Number} mediaType 
     * @param {Number} lang 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page  (default to <.>)
     * @param {Number} opts.size  (default to <.>)
     * @param {module:api/MediaControllerApi~getMediaByCategoryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getMediaByCategory(cat, mediaType, lang, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'cat' is set
      if (cat === undefined || cat === null) {
        throw new Error("Missing the required parameter 'cat' when calling getMediaByCategory");
      }
      // verify the required parameter 'mediaType' is set
      if (mediaType === undefined || mediaType === null) {
        throw new Error("Missing the required parameter 'mediaType' when calling getMediaByCategory");
      }
      // verify the required parameter 'lang' is set
      if (lang === undefined || lang === null) {
        throw new Error("Missing the required parameter 'lang' when calling getMediaByCategory");
      }

      let pathParams = {
        'cat': cat,'mediaType': mediaType,'lang': lang
      };
      let queryParams = {
        'page': opts['page'],'size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PageAlbumDTO;

      return this.apiClient.callApi(
        '/v1/media/{cat}/{mediaType}/{lang}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getMediaById operation.
     * @callback moduleapi/MediaControllerApi~getMediaByIdCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AlbumDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} id 
     * @param {module:api/MediaControllerApi~getMediaByIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getMediaById(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getMediaById");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = AlbumDTO;

      return this.apiClient.callApi(
        '/v1/media/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateMedia operation.
     * @callback moduleapi/MediaControllerApi~updateMediaCallback
     * @param {String} error Error message, if any.
     * @param {Object{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/AlbumEntity} body 
     * @param {String} authorization 
     * @param {module:api/MediaControllerApi~updateMediaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateMedia(body, authorization, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateMedia");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling updateMedia");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/v1/media/manage/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}