<template>
  <VueEditor id="editor" ref="editorRef" style="width: 100%; height: 100%; min-height: 250px;" v-model="_content"
    :editor-toolbar="customToolbar" useCustomImageHandler @imageAdded="handleImageAdded"
    @update:modelValue="updateContent" />
    <!-- <button type="button" @click="triggerAudioUpload" class="custom-audio-upload-button">🎵 Upload Audio</button> -->

    <!-- <input type="file" ref="audioInput" style="display: none" @change="uploadAudio" accept="audio/*" /> -->

</template>
  
<script setup>
import { VueEditor } from "vue3-editor";
// import Quill from 'quill';

import { onMounted, nextTick, ref, watch } from "vue";
import { uploadFileToFirebase } from "../firebase/Utils.js"

// const BlockEmbed = Quill.import('blots/block/embed');

// class AudioBlot extends BlockEmbed {
//   static create(url) {
//     let node = super.create('audio'); // Ensure the node is an 'audio' element
//     if (url) {
//       node.setAttribute('src', url);
//       node.setAttribute('controls', true);
//       node.style.width = '50%'; // Set width to 100% to make audio control responsive
//     }
//     return node;
//   }

//   static value(node) {
//     return node.getAttribute('src');
//   }
//   remove() {
//        super.remove();
//      }
// }

// AudioBlot.blotName = 'audio';
// AudioBlot.tagName = 'audio';
// Quill.register(AudioBlot, true); // Pass true to overwrite any existing Blot with the same name

const props = defineProps(['content', 'modelValue']);
const emit = defineEmits(['update:modelValue']);

const editorRef = ref(null);
const audioInput = ref(null);

let _content = ref(props.content);

const customToolbar = [
  [{ header: [false, 2, 1] }],
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline"],
  [
    { align: "" },
    { align: "center" },
    { align: "right" },
    { align: "justify" }
  ],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  ["image", "video"],
  ["clean"]
];

watch(
  () => props.content,
  (newContent) => {
    if (newContent) {
      _content.value = newContent;
    } else {
      _content.value = "";
    }
  }
);

// function triggerAudioUpload() {
//   audioInput.value.click();
// }


const handleImageAdded = async (file, Editor, cursorLocation, resetUploader) => {
  var formData = new FormData();
  formData.append("image", file);
  const url = await uploadFileToFirebase(file, "articles/images/", file.name);
  Editor.insertEmbed(cursorLocation, "image", url);
  resetUploader();
};
// const uploadAudio = async (event) => {
//   console.log(event);
//   const file = event.target.files[0];
//   if (file) {
//     const url = await uploadFileToFirebase(file, "articles/audios/", file.name);
//     console.log(url);
//     const range = editorRef.value.quill.getSelection(true);
//     editorRef.value.quill.insertEmbed(range.index, 'audio', url);
//   }
// };

// function AudioButton() {
//   return {
//     icon: '🎵', // You can use an SVG or an icon here
//     handler: function () {
//       audioInput.value.click();
//     }
//   };
// }
onMounted(() => {
  nextTick(() => {
    if (editorRef.value) {
      editorRef.value.quill.format('direction', 'rtl');
      editorRef.value.quill.format('align', 'right');

    }
  });
});
const updateContent = (value) => {
  _content.value = value;
  emit('update:modelValue', value);
};
</script>
<style>
.ql-editor li:not(.ql-direction-rtl):before {
    margin-left: 0;
    margin-right: .3em;
    text-align: right;
}
</style>