import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// import { VueFire, VueFireAuth } from 'vuefire'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB4qE6mHCJ25IjZUhdeapf3Z45-a6jtWY4",
  authDomain: "hazemdev.firebaseapp.com",
  databaseURL: "https://hazemdev-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hazemdev",
  storageBucket: "hazemdev.appspot.com",
  messagingSenderId: "941595306107",
  appId: "1:941595306107:web:9e25868b8a8e3c6c7fdade"
};


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

createApp(App).use(router).mount('#app')
