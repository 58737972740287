<template>
  <div :class="['d-flex flex-column vh-100 bg-light', { 'toggled': isCollapsed }]" id="sidebar-wrapper">
    <div class="logo py-4 px-3">
      <img :class="{'img-fluid': !isCollapsed, 'img-collapsed': isCollapsed}" src="../assets/logo-small.png" alt="Logo" />
    </div>
    <div class="list-group list-group-flush">
      <router-link to="/home/dashboard" exact :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="toggleActive">
        <i class="fas fa-home"></i> <span v-if="!isCollapsed">الرئيسية</span>
      </router-link>
      <router-link to="/home/categories" :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="toggleActive">
        <i class="fas fa-folder"></i> <span v-if="!isCollapsed">الأقسام</span>
      </router-link>
      <router-link to="/home/articles" :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="toggleActive">
        <i class="fas fa-newspaper"></i> <span v-if="!isCollapsed">المقالات</span>
      </router-link>
      <router-link to="/home/media" :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="toggleActive">
        <i class="fas fa-image"></i> <span v-if="!isCollapsed">الوسائط</span>
      </router-link>
      <router-link to="/home/users" :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="toggleActive">
        <i class="fas fa-users"></i> <span v-if="!isCollapsed">المستخدمون</span>
      </router-link>
      <a href="#" :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="logout">
        <i class="fas fa-sign-out-alt"></i> <span v-if="!isCollapsed">تسجيل الخروج</span>
      </a>
    </div>
    <button @click="toggleCollapse" class="btn btn-secondary mt-auto">
      <i :class="['fas',isCollapsed? 'fa-angle-double-left':'fa-angle-double-right']"></i>
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const isCollapsed = ref(true);

const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value;
};

const logout = () => {
  localStorage.clear();
  window.location.reload();
};

const toggleActive = (event) => {
  const currentActive = document.querySelector('.list-group-item.active');
  if (currentActive) {
    currentActive.classList.remove('active');
  }
  event.currentTarget.classList.add('active');
};
</script>

<style scoped>
#sidebar-wrapper.toggled {
  width: 80px; /* Adjust the width for collapsed state */
}

.logo img {
  transition: opacity 0.3s ease-in-out, width 0.3s ease;
}

.toggled .logo img {
  opacity: 0.5; /* Adjust opacity if needed */
  width: 50px; /* Smaller width when collapsed */
}

.img-collapsed {
  width: 50px; /* Smaller width */
  transition: width 0.3s ease;
}

.list-group-item {
  transition: background-color 0.3s;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

.list-group-item.active {
  background-color: #e9ecef;
  font-weight: bold;
}

.btn-secondary {
  width: 100% !important;
  border-radius: 0;
}
</style>