<template>
    <div>
        <div class="form-check form-check-inline">
            <label class="form-check-label">اللغة:</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1"
                v-model="selectedOption" @change="handleChange" />
            <label class="form-check-label" for="inlineRadio1">عربي</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2"
                v-model="selectedOption" @change="handleChange" />
            <label class="form-check-label" for="inlineRadio2">أنجليزي
            </label>
        </div>
    </div>
</template>
  
<script setup>
import {ref,watch,defineProps} from "vue"
const props = defineProps(["initialValue"]);
const selectedOption = ref('1');
const emit = defineEmits(['selectedOption']);

watch(
    () => props.initialValue,
    (newInitialValue) => {
        selectedOption.value=newInitialValue;
    })
function handleChange() {
    emit('selectedOption', selectedOption);

}

</script>