<template>
  <div class="d-flex align-items-center mt-3">
    <input 
      type="text" 
      class="form-control form-control-lg border-primary rounded me-2" 
      placeholder="بحث..." 
      v-model="searchQuery" 
      @input="handleInput" 
      @keyup.enter="submitSearch"
    />
    <button class="btn bg-transparent btn-outline-transparent" @click="submitSearch">
      <i class="fa fa-search"></i>
    </button>  
  </div>
</template>

<script setup>

import { ref } from "vue";

const searchQuery = ref('');

const emit = defineEmits(['submit:search']);


const handleInput = () => {
};

const submitSearch = () => {
  if(searchQuery.value.length>2){
    emit('submit:search',searchQuery.value);
  }
  };
</script>
