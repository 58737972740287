<template>
    <div class="container-fluid">

        <h2 class="text-center">إدارة الأقسام</h2>
        <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center">
                <button class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" @click="openModal()">
                    <i class="fas fa-plus"></i> جديد
                </button>
                <SearchComponent @submit:search="startSearch"/>
            </div>
        </div>

        <CategoryModal :category="selectedCategory" />
        <!-- :categories="categories"  -->


        <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>الاسم العربي</th>
                    <th>الاسم الانجليزي</th>
                    <th>النوع</th>
                    <th>ضمن</th>
                    <th>عمليات</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="category in categories" :key="category.id">
                    <!-- <td>{{ category.id }}</td> -->
                    <td>{{ category.nameAr }}</td>
                    <td>{{ category.nameEn }}</td>
                    <td>{{ category.isMediaCategory ? "وسائط" : "مقالات" }}</td>
                    <td>{{ category.parent != null ? category.parent.nameAr : '' }}</td>
                    <td>
                        <a class="btn btn-info" :href="'https://hazemdev.web.app/category/' + category.id" target="_blank">
                            عرض
                        </a>
                        <button class="edit-button" @click="editCategory(category)" data-toggle="modal" data-target="#exampleModal">
                            تعديل
                        </button>
                        <button class="delete-button" @click="deleteCategory(category.id)">
                            حذف
                        </button>
                        </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-start">
                    <PaginationComponent :total-elements="totalElements" :page-size="pageSize" :current-page="currentPage"
                        @page-changed="handlePageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { CategoryControllerApi,SearchControllerApi, ApiClient, CategoryEntity } from "../api/index.js";
import { ref } from 'vue'
import CategoryModal from "../components/CategoryModal.vue";
import PaginationComponent from '../components/PaginationComponent.vue';
import SearchComponent from '../components/SearchComponent.vue';


const apiClient = new ApiClient();
const categoryApi = new CategoryControllerApi(apiClient);
const searchApi=new SearchControllerApi(apiClient);
const categories = ref([]);

const searchMode=ref(false);
const query=ref(null);
const totalElements = ref(0);
const pageSize = ref(10);
const currentPage = ref(0);
const selectedCategory = ref(null);



const fetchCategories = () => {
    if(searchMode.value==false)
    categoryApi.getCategories({ page: currentPage.value, size: pageSize.value, isChild: true }, (error, data, response) => {
        if (error)
            alert(error);
        parseResult(data);
    });
    else
    searchApi.searchCategories(currentPage.value,pageSize.value,query.value,localStorage.getItem('token'),(error,data,response)=>{
        if (error)
            alert(error);
        parseResult(data);
    });
};

function parseResult(data){
    categories.value = data.content;
    currentPage.value = data.pageable.pageNumber;
    pageSize.value = data.pageable.pageSize;
    totalElements.value = data.totalElements;
}

const editCategory = (category) => {
    selectedCategory.value = category;

}

const deleteCategory = (id) => {
    categoryApi.deleteCategory(id, localStorage.getItem("token"), (error, data, response) => {
        if (data == true)
            location.reload();
        else {
            alert("لا يمكن حذف القسم نظرًا لوجود مقالات فيه");
        }
    });
}


fetchCategories();
const handlePageChange = (newPage) => {
    currentPage.value = newPage;
    fetchCategories();
};

const openModal = () => {
  selectedCategory.value = null;
};

const startSearch = (_) => {
    currentPage.value=0;
    searchMode.value=true;
    query.value=_;
    fetchCategories();
};

</script>
